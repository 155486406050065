<!--
 * @Author: your name
 * @Date: 2020-04-09 16:09:28
 * @LastEditTime: 2020-12-15 14:43:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /cunw-youzhiduo-OS/src/views/index.vue
-->
<template>
  <div class="after-sale-service">
    <div class="main-content">
      <img class="concept-banner" src="../../assets/images/aftrtBanner.png">
      <div class="content-block">
        <div class="clause-title">
          <p>
            感谢您使用湖南新云网科技有限公司（以下简称“新云网”）的产品和服务。
          </p>
          <p>
            本公司的产品严格依据《中华人民共和国消费者权益保护法》、《中华人民共和国产品质量法》实行售后服务，服务内容如下：
          </p>
        </div>
        <div class="single-block">
          <h3>
            一、 退换货政策
          </h3>
          <p>
            1、凡购买课桌先生的用户，可在自签收次日起七日内享受无理由退货服务。退货
            时用户须出示有效购买凭证，并退回发票。用户须保证退货商品保持原有品质和功能、外观完好、商品及配件的商标和各种标识完整齐全，如有赠品需一并退回。如果商品出现人为损坏、包装箱缺失、零配件缺失的情况，不予办理退货。
          </p>
          <p>
            2、自收到退货商品之日起七日内向用户返还已支付的货款。退款方式按照用户的支付方式原路退回。具体到账日期可能会受银行、支付机构等因素影响。
          </p>
          <p>
            3、自用户签收次日起七日内，发生非人为损坏性能故障，经由新云网售后服务中心检测确认后，将免费为用户提供维修服务。若用户申请办理退货业务，退货时用户须出示有效购买凭证，并退回发票，如有赠品需一并退回。
          </p>
          <p>
            4、自用户签收次日起十五日内，发生非人为损坏性能故障，经由新云网售后服务中心检测确认后，将免费为用户提供维修服务。若用户申请办理换货业务，更换整套商品。换货后，商品本身三包期重新计算。
          </p>
        </div>
        <div class="single-block">
          <h3>
            二、 保修政策
          </h3>
          <p>
            自用户签收次日起，发生非人为损坏性能故障，经由新云网售后服务中心检测确认
            后，为用户办理维修业务。整机及各零部件非人为损坏保修期限如下：
          </p>
          <table border="1" class="table-block" bordercolor="#A0A0A0">
            <tr>
              <th class="first-th">
                保修内容
              </th>
              <th class="secend-th">
                保修期限
              </th>
            </tr>
            <tr>
              <td>木桌板</td>
              <td>三年</td>
            </tr>
            <tr>
              <td>五金（含装饰塑胶件）</td>
              <td>三年</td>
            </tr>
            <tr>
              <td>电脑面板及其配件</td>
              <td>一年</td>
            </tr>
            <tr>
              <td>
                整桌升降把手、
                主桌板角度调节把手、
                主桌板升降手柄
              </td>
              <td>一年</td>
            </tr>
          </table>
        </div>
        <div class="single-block">
          <h3>
            三、 非保修条款
          </h3>
          <p>
            以下情况不属于保修范围：
          </p>

          <p>
            1、超过三包有效期限；
          </p>
          <p>
            2、无保修卡、发票或保修卡与发票信息不符；
          </p>
          <p>
            3、人为原因造成的损坏；
          </p>
          <p>
            4、不可抗拒因素造成的损坏；
          </p>
          <p>
            5、未按产品使用说明书的要求使用、保养造成的任何损坏；
          </p>
          <p>
            6、超出正常使用条件，强行使用本产品造成的故障或损伤；
          </p>
          <p>
            7、未经新云网公司授权的人员私自拆动或修理；
          </p>
          <p>
            8、恶意损坏保修卡内容、产品信息，包括模糊破坏、自行撕毁、篡改等；
          </p>
          <p>
            9、其他非产品本身设计、制造、质量等问题而导致的故障和损坏。
          </p>
        </div>
        <div class="single-block">
          <h3>
            四、联系客服
          </h3>
          <p>
            拨打新云网全国统一服务热线400-870-1117，400电话服务时间周一至周日9:00-
            18:00。
          </p>
          <p>
            微信搜索小程序“新云网服务”，或微信扫描下图二维码进行售后服务预约。
          </p>
        </div>
        <div class="applets">
          <img class="pc" src="../../assets/images/appletsPc.png">
          <img class="mb" src="../../assets/images/appletsMb.png">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Index',
  data() {
    return {};
  }
};
</script>
<style lang="scss" scope>
@media screen and (min-width: 1400px) {
  .after-sale-service{
    width: 100%;
    height: auto;
    background: #fff;
    overflow: hidden;
    margin-top: 10px;
    .main-content{
      margin: 0 auto;
      width: 1400px;
      background: #fff;
      height: auto;
      .concept-banner{
        width: 100%;
        height: auto;
      }
      .content-block{
        width: 1200px;
        margin: 0 auto;
        height: auto;
      }
      .clause-title{
        font-size: 28px;
        font-weight: bold;
        color: #4EB4E5;
        text-indent: 56px;
        margin-top: 12px;
        line-height: 56px;
      }
      .single-block{
        h3{
          font-size: 28px;
          font-weight: bold;
          color: #4EB4E5;
          text-indent: 56px;
          margin-top: 14px;
        }
        p{
          font-size: 22px;
          color: #676767;
          text-indent: 44px;
          line-height: 44px;
        }
        .table-block{
          border-collapse: collapse;
          width: 100%;
          color: #747474;
          font-size: 22px;
          text-align: center;
          margin-top: 50px;
          tr{
            padding: 12px 0;
          }
          th{
            font-size: 22px;
            padding: 12px 0;
          }
          td{
            font-size: 22px;
            padding: 12px 0;
          }
          .first-th{
            width: 76%;
          }
          .secend-th{
            width: 24%;
          }
        }
      }
      .applets{
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 40px 0 80px;
        .mb{
          display: none;
        }
        img{
          width: 300px;
          height: auto;
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
.after-sale-service{
    width: 100%;
    height: auto;
    background: #fff;
    overflow: hidden;
    margin-top: .167rem;
    .main-content{
      margin: 0 auto;
      width: 100%;
      background: #fff;
      height: auto;
      .concept-banner{
        width: 100%;
        height: auto;
      }
      .clause-title{
        font-size: .373rem;
        font-weight: bold;
        color: #4EB4E5;
        text-indent: .746rem;
        margin-top: .32rem;
        padding: 0 .8rem;
      }
      .single-block{
        padding: 0 .8rem;
        h3{
          font-size: .373rem;
          font-weight: bold;
          color: #4EB4E5;
          text-indent: .746rem;
          margin-top: .32rem;
        }
        p{
          font-size: .32rem;
          color: #676767;
          text-indent: .64rem;
          line-height: .587rem;
        }
        .table-block{
          border-collapse: collapse;
          width: 100%;
          color: #747474;
          font-size: .32rem;
          text-align: center;
          margin-top: .32rem;
          .first-th{
            width: 76%;
          }
          .secend-th{
            width: 24%;
          }
        }
      }
      .applets{
        width: 100%;
        display: flex;
        justify-content: center;
        margin: .427rem 0 .9rem;
        .pc{
          display: none;
        }
        img{
          width: 2.43rem;
          height: 100%;
        }
      }
    }
  }
}
</style>
